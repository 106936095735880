<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!customer">
      <span class="loader"></span>
    </span>
    <div v-if="customer" class="elite-tabs-wrapper">
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="global"
      >
        <tab-pane title="global" id="1" :active="true">
          <span slot="title">
            <i class="ni ni-cloud-upload-96"></i>
            {{ $t("COMMON.GLOBAL") }}
          </span>
          <customer-view-global :customer="customer" @customerUpdated="get" />
        </tab-pane>

        <tab-pane
          title="contacts"
          id="3"
          :active="true"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_CONTACTS)"
        >
          <span slot="title">
            <i class="ni ni-circle-08"></i>
            {{ $t("COMMON.CONTACTS") }}
          </span>
          <customer-view-contacts :customer="customer" />
        </tab-pane>

        <tab-pane
          title="files"
          id="998"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_FILES)"
        >
          <span slot="title">
            <i class="ni ni-folder-17"></i>
            {{ $t("COMMON.FILES") }}
          </span>
          <div class="elite-tabs-wrapper-content">
            <list-file-component :object="customer" />
          </div>
        </tab-pane>

        <tab-pane
          title="logs"
          id="999"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
        >
          <span slot="title">
            <i class="fa fa-file"></i>
            {{ $t("COMMON.LOGS") }}
          </span>
          <customer-view-logs :customer="customer" />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { Tabs, TabPane } from "@/components";
import ListFileComponent from "@/components/File/ListFileComponent.vue";
import CustomerViewLogs from "../partials/CustomerViewLogs.vue";
import CustomerViewContacts from "../partials/CustomerViewContacts.vue";
import CustomerViewGlobal from "../partials/CustomerViewGlobal.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    CustomerViewGlobal,
    CustomerViewLogs,
    CustomerViewContacts,
    ListFileComponent,
  },

  mixins: [],

  props: {
    customerId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      customer: null,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch("customers/get", this.customerId);
        this.customer = this.$store.getters["customers/customer"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
